import { Injectable } from '@angular/core';
import { lastValueFrom, map } from 'rxjs';
import { API_URL } from 'src/app/constants/url';
import { HttpService } from 'src/app/services/http/http.service';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(private _http: HttpService) { }

  login(data:any) {
    return lastValueFrom(this._http.get(API_URL.login, data).pipe(map(response => {
      const data = response as unknown as any;
      return data;
    })))
  }

  validateOtp(data:any) {
    return lastValueFrom(this._http.get(API_URL.validateOtp, data).pipe(map(response => {
      const data = response as unknown as any;
      return data;
    })))
  }

  getUserDetail() {
    return lastValueFrom(this._http.get(API_URL.userDetails))
  }
}
