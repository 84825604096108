import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { AuthAction } from './auth.actions';
import { PublicService } from 'src/app/public/services/public.service';
import { Token } from '@token';
import { PopupService } from 'src/app/services/popup';
import { OTP_SENT } from 'src/app/constants/messages.constants';

export interface AuthStateModel {
    tenentId: string;
    isValidEmail: boolean;
    profileData: any;
    heading: string;
    isLoginPage: boolean;
    showHomeIcon: boolean;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
         tenentId: '',
         isValidEmail: true,
         profileData: {},
         heading: '',
         isLoginPage: true,
         showHomeIcon: true
    }
    
})
@Injectable()
export class AuthState {
    @Selector() 
    static profileData(state: AuthStateModel) {
       return state.profileData
    }

    @Selector()
    static getState(state: AuthStateModel) {
        return state;
    }

    @Selector()
    static tenentId(state: AuthStateModel) {
        return state.tenentId
    }

    @Selector() 
    static heading(state:AuthStateModel) {
        return state.heading
    }

    @Selector() 
    static isLoginPage(state:AuthStateModel) {
        return state.isLoginPage
    }

    @Selector() 
    static showHomeIcon(state:AuthStateModel) {
        return state.showHomeIcon
    }

    constructor(private _public: PublicService, private _token: Token, private _popup: PopupService) { }

    @Action(AuthAction.Login)
    async login({patchState}: StateContext<AuthStateModel>, action: AuthAction.Login) {
       let params = {...action.data};
       params.conferenceId = this._token.conferenceId;
       let response = await this._public.login(params)
       this._popup.success(OTP_SENT)
       if(response) {
        patchState({
            tenentId: response.id
         })
       }
    }

    @Action(AuthAction.ValidateOtp)
    async validateOtp({getState}: StateContext<AuthStateModel>, action: AuthAction.Login) {
       const state = getState()
       let params = {...action.data};
       let response = await this._public.validateOtp(params)
       this._token.value = response.accessToken;
    }

    @Action(AuthAction.AuthGuardHandler)
    async authGuardHandler({patchState}: StateContext<AuthStateModel>) {
        if(this._token.hasValue) {
            let profile = await this._public.getUserDetail();
            if(profile) {
                patchState({
                    profileData: profile
                })
            }
        }
    }

    @Action(AuthAction.ListenHeading)
    listenHeading({patchState}: StateContext<AuthStateModel>, action: AuthAction.ListenHeading) {
        patchState({
            heading: action.heading
        })
    }

    @Action(AuthAction.DetectLoginPage)
    detectLogin({patchState}: StateContext<AuthStateModel>, action: AuthAction.DetectLoginPage) {
        patchState({
            isLoginPage: action.isLoginPage
        })
    }

    @Action(AuthAction.HideShowHomeIconAction)
    hideShowHomeIcon({patchState}: StateContext<AuthStateModel>, action: AuthAction.HideShowHomeIconAction) {
        patchState({
            showHomeIcon: action.data
        })
    }

    @Action(AuthAction.ResetProfile) 
    resetProfile({patchState}: StateContext<AuthStateModel>) {
        patchState({
            profileData: null
        })
    }

}
